
import Vue from 'vue'
import dayjs from 'dayjs'

import ScrollablePanels from '@/components/molecules/ScrollablePanels2.vue'
import DatePager from '@/components/atoms/DatePager.vue'

import { dailyScheduleModule } from '@/store/viewModules/work-schedule/dailyShceduleModule'
import { ShiftPatternResponse, WorkSchedulePlacementSchema,  AttendanceScheduleMonthlySchema, AttendanceScheduleChildPartSchema, AttendanceScheduleDailyPartSchema, SearchNurseryAttendanceResponse, WorkDescriptionSettingResponse } from 'chaild-api/lib'

import { attendanceScheduleModule } from '@/store/viewModules/work-schedule/attendanceScheduleModule'
import { attendanceModule } from '@/store/dataModules/attendanceModule'
import LocalDataService from '@/service/LocalDataService'
import { monthlyScheduleModule } from "@/store/viewModules/work-schedule/monthlyScheduleModule";
import { DayScheduleUpdateRequestTimetable } from '@/api/ApiShift'
import { NurseryUserResponse } from "chaild-api/src/component/nursery/user/types";
import { workDescriptionModule } from "@/store/viewModules/work-schedule/workDescriptionModule";
import { ShiftTimetableCategoryType } from 'chaild-api/src/constant/shiftTimetableCategory'
import HourMinuteInput from '@/components/molecules/HourMinuteInput.vue'


interface DataType {
  isFetching: boolean;
  isCalcOpen: boolean;
  currentTab: number;
  searchFilters: any;
  selectedClassId: number | string | null;
  isSelecting: boolean;
  selectedCells: any;
  firstselectedCells: any;
  showMenu: boolean;
  menuPosition: any;
  contextMenuOptions: any;
  isOverlayVisible: boolean;
  tablePosition: any;
  tableIDCounter: number;
  lastAbbreviation: string | null;
  lastTableID: string | null;
  editingUser: any;
  selectedUser: any;
  editingDate: string | number | null;
  editingStart: number;
  editingEnd: number;
  deleateOn: boolean;
  deleateTableId: any;
  isDeleateFromHead: boolean;
  isDrawingErorr: boolean;
  isFillCell: boolean;
  partialDelete: any;
  loading: boolean;
  loadingCountDenominator: number;
  loadingCountNumerator: number;
  editingChildSchedule: AttendanceScheduleDailyPartSchema | null;
  editingChild: AttendanceScheduleChildPartSchema | null;
  isUpdatingSchedule: boolean;
  isUpdatePlacement: boolean;
}

interface DataRow {
  name: string;
  items: ScheduleHour[];
  userId: number;
}

interface ScheduleHour {
  hour: number;
  items: QuarterlyShcedule[];
}

interface ScheduleTableRowItem {
  name: string;
  attendanceCount: number;
  offCount: number;
  schedules: AttendanceScheduleDailyPartSchema[];
  child: AttendanceScheduleChildPartSchema;
}

interface QuarterlyShcedule {
  abbreviation?: string | null;
  color?: string | null;
  isFilled: boolean;
  inSchool?: boolean;
  minute: number;
  requisite?: {
    govExpected: number | null;
    govActual: number | null;
    expected: number | null;
    actual: number | null;
  };
  sufficient?: {
    expected: number | null;
    actual: number | null;
  };
  attendance?: {
    age0: number | null;
    age1: number | null;
    age2: number | null;
    age3: number | null;
    age4: number | null;
  };
  tableID?: number;
  tableInfo?: WorkDescriptionSettingResponse | null;
  category?: ShiftTimetableCategoryType | null;
}

const minutes = [0, 15, 30, 45]
const hours = [...Array(24)].map((emp, i) => i)
const emptySchedule: ScheduleHour[] = hours.map((hour) => {
  const items: QuarterlyShcedule[] = minutes.map((minute) => ({
    isFilled: false,
    minute
  }))
  return {
    hour,
    items
  }
})

const AbsenseReasons = [
  {
    text: '発熱',
    value: 'fever'
  },
  {
    text: '家庭の事情',
    value: 'personal'
  },
  {
    text: '溶連菌感染症',
    value: 'infStrep'
  },
  {
    text: 'マイコプラズマ肺炎',
    value: 'infMycoplasma'
  },
  {
    text: '手足口病',
    value: 'infHFMD'
  },
  {
    text: '伝染性紅斑（りんご病）',
    value: 'infSlappedCheek'
  },
  {
    text: 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）',
    value: 'infStomach'
  },
  {
    text: 'ヘルパンギーナ',
    value: 'infHerpangina'
  },
  {
    text: 'RSウィルス感染症',
    value: 'infRS'
  },
  {
    text: '帯状疱疹',
    value: 'infZoster'
  },
  {
    text: '突発性発しん',
    value: 'infRoseola'
  },
  {
    text: '伝染性膿痂疹（とびひ）',
    value: 'infImpetigo'
  },
  {
    text: 'その他',
    value: 'others'
  },
]

export default Vue.extend({
  components: {
    ScrollablePanels,
    DatePager,
    HourMinuteInput,
  },
  data: (): DataType => ({
    isFetching: false,
    isCalcOpen: true,
    currentTab: 0, // デフォルトでタブ1を表示
    searchFilters: {
        date: dayjs().format("YYYY-MM-DD"),
        age: "",
        classId: "",
        gender: "",
        status: "" as string,
        limit: 1000,
        skip: 0,
      },
    isSelecting: false,
    selectedCells: [],
    firstselectedCells: [],
    selectedClassId: null,
    showMenu: false,
    menuPosition: { x: 0, y: 0 },
    contextMenuOptions: ["休憩"], // 表示したい選択肢
    isOverlayVisible: false,
    tablePosition: [], // 位置情報を保存するオブジェクト
    tableIDCounter: 1,  // tableID用のカウンター
    lastAbbreviation: null, // 前の abbreviation を記録
    lastTableID: null, // 前の tableID を記録
    editingUser: [],
    selectedUser: [],
    editingDate: null,
    editingStart: 0,
    editingEnd: 0,
    deleateOn: false,
    deleateTableId: [],
    isDeleateFromHead: true,
    isDrawingErorr: false,
    isFillCell: false,
    partialDelete: [],
    loading: false,
    loadingCountDenominator: 1,
    loadingCountNumerator: 0,
    editingChildSchedule: null,
    editingChild: null,
    isUpdatingSchedule: false,
    isUpdatePlacement: false,
  }),

  watch: {
    async yearMonthDate(val: string) {
      const date = dayjs(val).format('YYYY-MM-DD')
      attendanceScheduleModule.onYearMonthChange(date)
      this.searchFilters.date = dayjs(val).format('YYYY-MM-DD');
      console.log(`日付遷移： ${this.searchFilters.date}`)
      this.searchAttendance();
    },
    selectedClassId: {
      deep: true,
      async handler() {
        this.searchFilters.classId = this.selectedClassId
        await this.searchAttendance();
      },
    },
  },

  computed: {
    yearMonthDate: {
      get() {
        return dailyScheduleModule.yearMonthDate
      },
      set(value: string) {
        dailyScheduleModule.setYearMonthDate(value)
        this.fetchDailySchedule()
      }
    },
    classes() {
      return attendanceScheduleModule.classes
    },
    dailySchedule(): ShiftPatternResponse[] {
      if (dailyScheduleModule.dailySchedule) {
        return dailyScheduleModule.dailySchedule.items
      }
      return []
    },
    placements(): WorkSchedulePlacementSchema[] {
      if (dailyScheduleModule.dailySchedule) {
        return dailyScheduleModule.dailySchedule.placements
      }
      return []
    },
    minutes(): number[] {
      return [0, 15, 30, 45]
    },
    hours() {
      return [...Array(24)].map((emp, i) => i)
    },
    emptySchedule(): ScheduleHour[] {
      return this.hours.map((hour) => {
        const items: QuarterlyShcedule[] = this.minutes.map((minute) => ({
          isFilled: false,
          minute
        }))
        return {
          hour,
          items
        }
      })
    },
    scheduleTableRows(): DataRow[] {
      return this.dailySchedule.map((schedule) => {
        let name = `${schedule.nurseryUser.lastName} ${schedule.nurseryUser.firstName}`
        if (name.length > 10) {
          name = name.slice(0, 10) + '...'
        }

        const userId = schedule.nurseryUser.userId

        const items = [...this.emptySchedule].map((hourItem) => {
          const minItems = hourItem.items.map((minute) => {
            const timeAsInt = (hourItem.hour * 60) + minute.minute

            const findSchedule = schedule.timetables.find((tt) => tt.startMin <= timeAsInt && tt.endMin > timeAsInt);
            const findAttendance = schedule.timecards.find((tc) => tc.startMin <= timeAsInt && tc.endMin > timeAsInt);

            if (findSchedule?.color) {
              // 白色とのコントラスト比を計算
              const contrastRatio = this.calculateContrastRatio(findSchedule.color, "#FFFFFF");
              const fontColor = contrastRatio < 3.0 ? "#000000" : "#FFFFFF";
              //console.log("コントラスト比： " + contrastRatio);
              //console.log("文字色： " +  fontColor);

              return {
                isFilled: true,
                inSchool: findAttendance ? findAttendance.inSchool : false,
                abbreviation: findSchedule.abbreviation,
                color: findSchedule.color,
                fontColor: fontColor,
                minute: minute.minute,
                tableID: findSchedule?.timetableId,
                tableInfo: findSchedule.workDescription,
                category: findSchedule.category
              }
            }

            return {
              isFilled: false,
              inSchool: findAttendance ? findAttendance.inSchool : false,
              minute: minute.minute
            }
          })

          return {
            hour: hourItem.hour,
            items: minItems
          }
        })

        return {
          name,
          userId,
          items
        }
      })
    },
    staffIndex() {
      const staffs: number[] = [];
      this.scheduleTableRows.forEach(row => {
        return staffs.push(row.userId)
      })
      return staffs
    },
    tableCols() {
      return emptySchedule.map((s) => {
        const items = s.items.map((item) => {
          const min = s.hour * 60 + item.minute
          const find = this.placements.find((p) => {
            return p.startMin === min
          })
          console.log(find)
          if (find) {
            item.requisite = find.requisite
            item.sufficient = find.sufficient
            item.attendance = find.attendance?.expected
          }
          return item
        })
        return {
          hour: s.hour,
          items
        }
      })
    },
    attendanceSchedules() {
      return attendanceScheduleModule.attendanceSchedules
    },
    childScheduleTableRows(): ScheduleTableRowItem[] {
      return this.attendanceSchedules.filter((schedule: AttendanceScheduleMonthlySchema) => {
        if (!this.selectedClassId) {
          return true
        }
        const findClass = schedule.child.nurseryClasses.find((nc) => nc.classId === this.selectedClassId)
        if (findClass) {
          return true
        }
        return false
      }).map((schedule: AttendanceScheduleMonthlySchema) => {
        let name = `${schedule.child.lastName} ${schedule.child.firstName}`
        if (name.length > 10) {
          name = name.slice(0, 10) + '...'
        }

        const filteredSchedules = schedule.dailySchedules.filter((dailySchedule) => dailySchedule.date === this.yearMonthDate);

        return {
          name,
          attendanceCount: 0,
          offCount: 0,
          schedules: filteredSchedules,
          child: schedule.child
        }
      })
    },
    attendances: function (): SearchNurseryAttendanceResponse | null {
      const attendancesData = attendanceModule.attendances?.attendances;
      //出欠管理の並び順を園児予定の並び順に合わせる
      if (!attendancesData) {
        return null; // attendancesがnullまたはundefinedの場合
      }

      const attendanceItems = attendancesData.map(row => {
        return {
          childId: row.child.childId,
          attendedAt: row.attendedAt,
          leftAt: row.leftAt
        };
      });

      const childIdsOrder = this.childScheduleTableRows.map(row => row.child.childId);

      childIdsOrder.map(childId =>
        attendanceItems.find(attendance => attendance.childId === childId)
      )

      return {
        attendances: attendanceItems,
        totalItemsCount: attendanceItems.length,
        summary: {}
      } as unknown as SearchNurseryAttendanceResponse;
    },
    workDescriptions() {
      return workDescriptionModule.workDescriptions;
    },
    isEditScheduleModalOpen: {
      get() {
        if (this.editingChildSchedule && this. editingChild) {
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.editingChildSchedule = null
          this.editingChild = null
        }
      }
    },
    absenseOptions() {
      return [
        {
          text: '出席',
          value: 'attending'
        },
        {
          text: '欠席',
          value: 'isAbsent'
        },
      ]
    },
    absenseReasons() {
      return AbsenseReasons
    },
  },
  methods: {
    async fetchDailySchedule() {
      //this.isFetching = true
      dailyScheduleModule.setDailySchedule(null)
      await dailyScheduleModule.getDailySchedule()
      //this.isFetching = false
    },
    async downloadShiftPattern() {
      this.isFetching = true;
      const response = await dailyScheduleModule.dlShiftPattern();
      if (response) {
        alert('エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。')
      }
      this.isFetching = false;
    },
    async downloadMonthlyShiftPattern() {
      this.isFetching = true;
      const response = await dailyScheduleModule.dlMonthlyShiftPattern();
      if (response) {
        alert('エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。')
      }
      this.isFetching = false;
    },
    toggleCalc() {
      this.isCalcOpen = !this.isCalcOpen
    },
    async searchAttendance() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await attendanceModule
          .searchAttendance({
            nurseryId,
            ...this.searchFilters,
          })
          .catch();
      }
    },
    formatHour(date: string | null | undefined) {
      if (date) {
        return dayjs(date).format("H");
      }
      return null;
    },
    formatMin(date: string | null | undefined) {
      if (date) {
        const minuteNumber = parseInt(dayjs(date).format("m"), 10);
        return minuteNumber
      }
      return null;
    },
    syncScroll(source: string) {
      // nextTickでDOMがレンダリングされてからrefを参照
      this.$nextTick(() => {
        const StaffPanel = this.$refs.StaffPanel as HTMLElement | null;
        const StaffleftPanel = this.$refs.StaffleftPanel as HTMLElement | null;
        const StaffcenterPanel = this.$refs.StaffcenterPanel as HTMLElement | null;
        const ChildleftPanel = this.$refs.ChildleftPanel as HTMLElement | null;
        const ChildcenterPanel = this.$refs.ChildcenterPanel as HTMLElement | null;

        // refが正しく取得できた場合にスクロール同期を行う
        if (StaffleftPanel && StaffcenterPanel) {
          if (source === 'staffleft') {
            StaffcenterPanel.scrollTop = StaffleftPanel.scrollTop;
          } else if (source === 'staffcenter') {
            StaffleftPanel.scrollTop = StaffcenterPanel.scrollTop;
          } 
        }
        if (ChildleftPanel && ChildcenterPanel) {
          if (source === 'childleft') {
            ChildcenterPanel.scrollTop = ChildleftPanel.scrollTop;
          } else if (source === 'childcenter') {
            ChildleftPanel.scrollTop = ChildcenterPanel.scrollTop;
          } 
        }
      });
    },
    // 選択を開始する
    startSelection(isfill, i, j, k, tableID, userID) {
      //初期化
      this.editingUser = [];
      this.selectedCells = [];
      this.selectedUser = [];

      this.isSelecting = true;
      this.firstselectedCells = [{ row: i, col: j, min: k }];
      this.selectedCells = [{ row: i, col: j, min: k }];
      this.isFillCell = isfill ? true: false;
      if (!this.editingUser.includes(userID)) {
          this.editingUser.push(userID);
        }
      if (this.isFillCell) {
        this.deleateTableId.push(tableID);
      }
    },
    // 選択範囲を拡張する
    selectRange(userID, isfill, i, j, k, tableID) {
      if (this.isSelecting && !this.isFillCell) {  //追加処理の選択時
        if (isfill) {
          this.isDrawingErorr = true;
        } else {
          this.isDrawingErorr = false;
        }

        // 一度選択されたセルをクリア
        this.selectedCells = [];
        this.selectedUser = [];
        
        // firstselectedCellsから現在選択されているi, j, kまでをループ
        if (i >= this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min <= j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows <= i; rows++) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions <= j*10 + k; positions++) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: rows, col: cols, min: mins });
            }
          }
        } else if (i >= this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min > j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows <= i; rows++) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions >= j*10 + k; positions--) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: rows, col: cols, min: mins });
            }
          }
        } else if (i < this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min <= j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows >= i; rows--) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions <= j*10 + k; positions++) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: rows, col: cols, min: mins });
            }
          } 
        } else if (i < this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min > j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows >= i; rows--) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions >= j*10 + k; positions--) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: rows, col: cols, min: mins });
            }
          }
        }

        // 選択された範囲に特定の処理を実行
        this.highlightSelectedCells(isfill);

        //編集スタッフを格納
        if (!this.editingUser.includes(userID)) {
          this.editingUser.push(userID);
        }
      } else if (this.isSelecting && this.isFillCell) {  //削除処理の選択時
        // 一度選択されたセルをクリア
        this.selectedCells = [];
        this.selectedUser = [];
        
        // firstselectedCellsから現在選択されているi, j, kまでをループ
        if (i >= this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min <= j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows <= i; rows++) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions <= j*10 + k; positions++) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: this.firstselectedCells[0].row, col: cols, min: mins });
            }
          }
        } else if (i >= this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min > j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows <= i; rows++) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions >= j*10 + k; positions--) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: this.firstselectedCells[0].row, col: cols, min: mins });
            }
          }
        } else if (i < this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min <= j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows >= i; rows--) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions <= j*10 + k; positions++) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: this.firstselectedCells[0].row, col: cols, min: mins });
            }
          } 
        } else if (i < this.firstselectedCells[0].row && this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min > j*10 + k) {
          for (let rows = this.firstselectedCells[0].row; rows >= i; rows--) {
            for (let positions = this.firstselectedCells[0].col*10 + this.firstselectedCells[0].min; positions >= j*10 + k; positions--) {
                // 選択されたセルをselectedCellsに追加
                const cols =  (positions - (positions % 10)) /10
                const mins = positions % 10
                this.selectedCells.push({ row: this.firstselectedCells[0].row, col: cols, min: mins });
            }
          }
        }
        // 選択された範囲に特定の処理を実行
        this.highlightSelectedCells(isfill);

        //編集スタッフを格納
        if (!this.editingUser.includes(userID)) {
          this.editingUser.push(userID);
        }

        //削除するテーブルIDを追加
        if (tableID != null && !this.deleateTableId.includes(tableID)) {
          this.deleateTableId.push(tableID);
          console.log("追加されたデータ: " + this.deleateTableId);
        }

        // 配列の長さが2以上で、最後から2番目のデータがtableIDと同じ場合（選択範囲を縮めたときに削除するテーブルIDを削除）
        if (this.deleateTableId.length >= 2 && this.deleateTableId[this.deleateTableId.length - 2] === tableID) {
          this.deleateTableId.pop(); // 最後のデータを削除
          console.log("最後のデータが削除されました: " + this.deleateTableId);
        }

      }
    },
    // 選択を終了する
    async endSelection(userID, isfill, event, i, j, k) {
      this.isSelecting = false;
      this.editingDate = this.yearMonthDate;
      await this.executeActionOnSelectedCells();
      if (!this.isFillCell && isfill) {
        alert("予定を上書きすることはできません。\nすでに入力済みの予定を削除し、新たに予定を入力してください。")
        this.isDrawingErorr = false;
        this.selectedCells = [];
      } else if(!this.isFillCell) {
        this.menuPosition = { x: event.clientX -200, y: event.clientY };
        this.showMenu = true;
      }
      //削除処理を実行
      if (this.isFillCell) {
        this.deleteSelectedTable();
      }
    },
    async handleMenuSelect(option) {
      this.isOverlayVisible = true;
      this.loading = true;
      this.showMenu = false;
      await this.addTimetableToEditingPattern(option);
      console.log("選択されたオプション: ", option);
      this.selectedCells = [];
      this.loading = false;
      this.loadingCountNumerator = 0;
      this.loadingCountDenominator = 1;
      this.isOverlayVisible = false;
    },
    closeMenu() {
      this.selectedCells = [];
      this.showMenu = false;
    },
    // 選択されたセルをハイライト
    highlightSelectedCells(isfill) {
      this.getCellClass(isfill, this.selectedCells[0].row, this.selectedCells[0].col, this.selectedCells[0].min)
      this.getCellClass(isfill, this.selectedCells.slice(-1)[0].row, this.selectedCells.slice(-1)[0].col, this.selectedCells.slice(-1)[0].min)
    },
    // 選択されたセルに特定の処理を実行
    executeActionOnSelectedCells() {
      const staffnumberFirst = this.selectedCells[0].row
      const staffnumberLast = this.selectedCells.slice(-1)[0].row
      const start = this.selectedCells[0].col * 60 + this.selectedCells[0].min * 15
      const end = this.selectedCells.slice(-1)[0].col * 60 + this.selectedCells.slice(-1)[0].min * 15

      if (start < end) {
      this.editingStart = start
      this.editingEnd = end
      } else {
        this.editingStart = end
        this.editingEnd = start
      }

      console.log("safjgasfghjadfgja"+ this.staffIndex)

      // 選択されたスタッフを編集対象にする
      if (staffnumberFirst > staffnumberLast) {
        for (let i = staffnumberLast; i <= staffnumberFirst; i++) {
          this.selectedUser.push(this.staffIndex[i])
          console.log("@@@@@@@@@@:" + this.selectedUser)
        }
      } else {
        for (let i = staffnumberFirst; i <= staffnumberLast; i++) {
          this.selectedUser.push(this.staffIndex[i])
          console.log("@@@@@@@@@@:" + this.selectedUser)
        }
      }
    },
    getCellClass(isfill: boolean, row: number, col: number, min: number) {
        // 選択されたセルに一致するかをチェック
        const isSelected = this.selectedCells.some(
          (cell) => cell.row === row && cell.col === col && cell.min === min
        );
        return isSelected ? 'selected-cell' : '';
    },
    //削除処理の開始
    async deleteSelectedTable() {
      this.isFetching = true;
      this.isOverlayVisible = true;
      this.loading = true;
      this.loadingCountNumerator = 1;

      await this.fetchDailySchedule();
      await this.initializeTablePosition();

      if (this.editingUser && this.editingDate) {
          const dateInt = dayjs(this.editingDate).date();
          const yearInt = parseInt(dayjs(this.editingDate).format("YYYY"));
          const monthInt = parseInt(dayjs(this.editingDate).format("MM"));
          await monthlyScheduleModule.getUserShiftPatternDay({
            userId: this.editingUser,
            year: yearInt,
            month: monthInt,
            day: dateInt,
          });
      }

      for (const cell of this.deleateTableId) {
        const matchingPositions = this.tablePosition.filter(position => position.tableID === cell);
        if (matchingPositions != null) {
          await this.initializeTablePosition();
        }
        const tabeleStart = matchingPositions[0].j * 60 + matchingPositions[0].k * 15;
        const tabeleEnd = matchingPositions.slice(-1)[0].j * 60 + matchingPositions.slice(-1)[0].k * 15;

        console.log("削除テーブル：" + JSON.stringify(matchingPositions));
        console.log("始点" + tabeleStart);
        console.log("終点" + tabeleEnd);
        //中抜き削除
        if (tabeleStart < this.editingStart && this.editingStart < tabeleEnd && tabeleStart < this.editingEnd && this.editingEnd < tabeleEnd) {
          // 後
          if (matchingPositions[0].category === "workDescription") {
            this.partialDelete.push({
              workDescriptionName: matchingPositions[0].tableInfo.name,
              start: this.editingEnd + 15,
              end: tabeleEnd,
              abbreviation: matchingPositions[0].tableInfo.abbreviation,
              color: matchingPositions[0].tableInfo.color,
              id: matchingPositions[0].tableInfo.workDescriptionId,
              category: matchingPositions[0].category,
            });
          } else if (matchingPositions[0].category === "break") {
            this.partialDelete.push({
              workDescriptionName: "休憩",
              start: this.editingEnd + 15,
              end: tabeleEnd,
              abbreviation: "休憩",
              color: "#808080",
              category: matchingPositions[0].category,
            });
          }
          if (this.partialDelete[0]) {
            this.editingDate = this.yearMonthDate;
            await this.addTimetableToEditingPattern("partialDelete");
            //前
            monthlyScheduleModule.editingShiftPattern?.timetables.forEach((timetable) => {
            if (timetable.timetableId === matchingPositions[0].tableID) {
                timetable.endMin = this.editingStart
            }
            });
          }
        } else if (tabeleStart < this.editingStart && this.editingStart <= tabeleEnd) {
          monthlyScheduleModule.editingShiftPattern?.timetables.forEach((timetable) => {
          if (timetable.timetableId === matchingPositions[0].tableID) {
              timetable.endMin = this.editingStart
          }
          });
        } else if (tabeleStart <= this.editingEnd && this.editingEnd < tabeleEnd) {
          monthlyScheduleModule.editingShiftPattern?.timetables.forEach((timetable) => {
          if (timetable.timetableId === matchingPositions[0].tableID) {
              timetable.startMin = this.editingEnd + 15
          }
          });
        } else {
          // 削除処理を実行
          try {
            await this.removeTimetableFromEditingPattern(cell);
            console.log("削除準備完了しました: " + cell);
          } catch (error) {
            console.error("削除準備に失敗しました: " + cell, error);
            continue; // エラーがあっても次の `cell` に進む場合は `continue`
          }
        }
      }

      try {
          await this.saveEditingPattern(this.editingUser)
          console.log("削除が成功しました: ");
        } catch (error) {
          console.error("削除に失敗しました: ");
        }

      this.selectedCells = [];
      this.deleateTableId = [];
      this.partialDelete = [];
      await this.fetchDailySchedule();
      await this.initializeTablePosition();
      this.isSelecting = false;
      this.isFillCell = false;
      this.isFetching = false;
      this.loading = false;
      this.loadingCountNumerator = 0;
      this.isOverlayVisible = false;
    },
    initializeTablePosition() {
      // scheduleTableRowsのデータを元にtablePositionを初期化
      this.scheduleTableRows.forEach((row, i) => {
        row.items.forEach((item, j) => {
          item.items.forEach((minItem, k) => {
            if (minItem.tableID) {
              this.tablePosition.push({ tableID: minItem.tableID, i, j, k, tableInfo: minItem.tableInfo, category: minItem.category});
            }
          });
        });
      });
      console.log("Initialized tablePosition:", this.tablePosition);
    },
   //時間枠の削除処理
    async removeTimetableFromEditingPattern(timetableId?: number) {
      if (monthlyScheduleModule.editingShiftPattern) {
        const newPattern = { ...monthlyScheduleModule.editingShiftPattern };
        if (timetableId) {
          newPattern.timetables = newPattern.timetables?.map((tt) => {
            if (tt.timetableId === timetableId) {
              tt.operationType = "remove";
            }
            return tt;
          });
        }
        monthlyScheduleModule.updateEditingPattern(newPattern);
      }
    },
    //時間枠の追加
    async addTimetableToEditingPattern(option) {
      this.loadingCountDenominator = this.selectedUser.length;

      // for...ofを使用して非同期ループを処理
      //for (const SelectedStaff of this.selectedUser) {
      this.editingDate = this.yearMonthDate;

      if (this.editingDate) {
        const dateInt = dayjs(this.editingDate).date();
        const yearInt = parseInt(dayjs(this.editingDate).format("YYYY"));
        const monthInt = parseInt(dayjs(this.editingDate).format("MM"));
        await monthlyScheduleModule.getUserShiftPatternDay({
          userId: this.selectedUser[0],
          year: yearInt,
          month: monthInt,
          day: dateInt,
        });
      }

      if (monthlyScheduleModule.editingShiftPattern) {
        console.log("aaaa" + monthlyScheduleModule.editingShiftPattern);
        console.log("ユーザ: " + this.editingUser);
        console.log("日付: " + this.editingDate);
        const newPattern = { ...monthlyScheduleModule.editingShiftPattern };

        if (option === "休憩") {
          const newTimetable: DayScheduleUpdateRequestTimetable = {
            operationType: "add",
            name: "休憩",
            abbreviation: "休憩",
            color: "#808080",
            startMin: this.editingStart,
            endMin: this.editingEnd + 15,
            comment: "",
            category: "break",
          };

          if (newPattern.timetables) {
            newPattern.timetables.push(newTimetable);
          } else {
            newPattern.timetables = [newTimetable];
          }

          // シフトパターンが未作成の場合、任意の名前、略称、色を追加
          if (!newPattern.abbreviation) {
            newPattern.abbreviation = "勤務";
            newPattern.color = "#029ec0";
            newPattern.name = "勤務";
          }

          if (newPattern.users) {
            newPattern.users=this.selectedUser;
          }

          //this.loadingCountNumerator += 1;
          await monthlyScheduleModule.updateEditingPattern(newPattern);
        } else if (option === "partialDelete") {
          // 部分削除の処理
          for (const cell of this.partialDelete) {
            console.log("rrrrrr" + cell.start + "-" + cell.end + "-" + cell.id);
            const newTimetable: DayScheduleUpdateRequestTimetable = {
              operationType: "add",
              name: cell.workDescriptionName,
              abbreviation: cell.abbreviation,
              color: cell.color,
              startMin: cell.start,
              endMin: cell.end + 15,
              comment: "",
              workDescriptionId: cell.id,
              category: cell.category,
            };

            if (newPattern.timetables) {
              newPattern.timetables.push(newTimetable);
            } else {
              newPattern.timetables = [newTimetable];
            }
          }

          if (newPattern.users) {
            newPattern.users=this.selectedUser;
          }

          await monthlyScheduleModule.updateEditingPattern(newPattern);
        } else {
          for (const dscription of this.workDescriptions) {
            if (dscription.name === option) {
              const newTimetable: DayScheduleUpdateRequestTimetable = {
                operationType: "add",
                name: dscription.name,
                abbreviation: dscription.abbreviation,
                color: dscription.color,
                startMin: this.editingStart,
                endMin: this.editingEnd + 15,
                comment: "",
                workDescriptionId: dscription.workDescriptionId,
                category: "workDescription",
              };

              if (newPattern.timetables) {
                newPattern.timetables.push(newTimetable);
              } else {
                newPattern.timetables = [newTimetable];
              }

              // シフトパターンが未作成の場合、任意の名前、略称、色を追加
              if (!newPattern.abbreviation) {
                newPattern.abbreviation = "勤務";
                newPattern.color = "#029ec0";
                newPattern.name = "勤務";
              }
              this.loadingCountNumerator += 1;

              if (newPattern.users) {
                newPattern.users=this.selectedUser;
              }

              await monthlyScheduleModule.updateEditingPattern(newPattern);
            }
          }
        }
      }

      // 選択されたスタッフごとの保存処理を待つ
      if (!this.partialDelete[0]) {
        await this.saveEditingPattern(this.selectedUser[0]);
      }
      if (!this.partialDelete[0]) {
        // 処理完了後の後続処理
        this.selectedCells = [];
        await this.fetchDailySchedule();
        await this.initializeTablePosition();
        this.isFetching = false;
      }
    },
    //更新したシフトを保存する
    async saveEditingPattern(SelectedStaff) {
      if (this.editingUser && this.editingDate) {
        console.log("Saving....")
        console.log(`editingDate: ${this.editingDate}`)
        const dateInt = dayjs(this.editingDate).date();
        const yearInt = parseInt(dayjs(this.editingDate).format("YYYY"));
        const monthInt = parseInt(dayjs(this.editingDate).format("MM"));


        await monthlyScheduleModule.saveEditingPatternDay({
          userId: SelectedStaff,
          year: yearInt,
          month: monthInt,
          day: dateInt,

        });
        monthlyScheduleModule.updateEditingPattern(null);
        this.editingDate = null;
      }
      await this.fetchDailySchedule();
    },
    //作業内容一覧の取得
    getWorkDescriptions() {
      this.workDescriptions.forEach ((dscription) => {
      this.contextMenuOptions.push(dscription.name)
      }); 
    },
    openEditScheduleModal(child: AttendanceScheduleChildPartSchema, schedule: AttendanceScheduleDailyPartSchema) {
      this.editingChild = child
      this.editingChildSchedule = schedule
    },
    async saveEditingShcedule() {
      if (this.editingChildSchedule && this.editingChild) {
        this.isUpdatingSchedule = true
        if (this.editingChildSchedule[0].status === 'attending') {
          await attendanceScheduleModule.updateChildAttendanceSchedule({
            date: this.yearMonthDate,
            childId: this.editingChild.childId,
            abbreviation: this.editingChildSchedule[0].abbreviation || undefined,
            color: this.editingChildSchedule[0].color || undefined,
            status: this.editingChildSchedule[0].status || undefined,
            statusReason: this.editingChildSchedule[0].statusReason || undefined,
            startMin: this.editingChildSchedule[0].startMin || undefined,
            statusComment: this.editingChildSchedule[0].statusComment || undefined,
            endMin: this.editingChildSchedule[0].endMin || undefined
          })
        } else {
          await attendanceScheduleModule.updateChildAttendanceSchedule({
            date: this.yearMonthDate,
            childId: this.editingChild.childId,
            abbreviation: this.editingChildSchedule[0].abbreviation || undefined,
            color: this.editingChildSchedule[0].color || undefined,
            status: this.editingChildSchedule[0].status || undefined,
            statusReason: this.editingChildSchedule[0].statusReason || undefined,
            startMin: 0,
            statusComment: this.editingChildSchedule[0].statusComment || undefined,
            endMin: 0
          })
        }
        this.isUpdatingSchedule = false
        this.isEditScheduleModalOpen = false
      }
    },
    async deleteEditingShcedule() {
      if (this.editingChildSchedule && this.editingChild) {
        this.isUpdatingSchedule = true
        await attendanceScheduleModule.deleteChildAttendanceSchedule({
          date: this.yearMonthDate,
          childId: this.editingChild.childId,
          abbreviation: this.editingChildSchedule.abbreviation || undefined,
          color: this.editingChildSchedule.color || undefined,
          status: this.editingChildSchedule.status || undefined,
          statusReason: this.editingChildSchedule.statusReason || undefined,
          startMin: this.editingChildSchedule.startMin || undefined,
          statusComment: this.editingChildSchedule.statusComment || undefined,
          endMin: this.editingChildSchedule.endMin || undefined
        })
        this.isUpdatingSchedule = false
        this.isEditScheduleModalOpen = false
      }
    },
    // 相対輝度を計算する関数
    calculateRelativeLuminance(hexColor: string): number {
      const r = parseInt(hexColor.slice(1, 3), 16) / 255;
      const g = parseInt(hexColor.slice(3, 5), 16) / 255;
      const b = parseInt(hexColor.slice(5, 7), 16) / 255;

      const luminance = (channel: number) => {
        return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
      };

      return 0.2126 * luminance(r) + 0.7152 * luminance(g) + 0.0722 * luminance(b);
    },
    // コントラスト比を計算する関数
    calculateContrastRatio(color1: string, color2: string): number {
      const luminance1 = this.calculateRelativeLuminance(color1);
      const luminance2 = this.calculateRelativeLuminance(color2);
      const lighter = Math.max(luminance1, luminance2);
      const darker = Math.min(luminance1, luminance2);
      return (lighter + 0.05) / (darker + 0.05);
    },
    openUpdatePlacement() {
      this.isUpdatePlacement = true;
    },
    async UpdateExpectedPlacement() {
      this.isFetching = true;
      this.isUpdatePlacement = false;
      await new Promise((resolve) => setTimeout(resolve, 30000));
      console.log("計算開始");
      await dailyScheduleModule.UpdateExpectedPlacement();
      await new Promise((resolve) => setTimeout(resolve, 30000));
      console.log("計算完了");
      await this.fetchDailySchedule();
      await this.$set(this.tableCols,hours[0],0);
      this.isFetching = false;
    },
  },
  async mounted() {
    console.log("kjlkhjdksh;fgujdaghjadkghjke")
    this.isFetching = true;
    const queries = this.$route.query
    if (queries.date) {
      const dateString = queries.date as string
      const date = dayjs(dateString).format('YYYY-MM-DD')
      dailyScheduleModule.setYearMonthDate(date)
    } else {
      const date = dayjs().format('YYYY-MM-DD')
      dailyScheduleModule.setYearMonthDate(date)
    }

    await Promise.all([
      this.fetchDailySchedule(),
      ///attendance-schedule/${year}/${month}/childrenを呼び出す（園児予定一覧のAPI）
      attendanceScheduleModule.listAttendanceSchedules(),
      attendanceScheduleModule.listClass(),
      ////GET/attendance/searchを呼び出す（出欠データのAPI）
      this.searchAttendance()
    ]);

    await Promise.all([
      this.initializeTablePosition(),
      workDescriptionModule.listWorkDescriptions(),
    ]);

    this.getWorkDescriptions()
    this.selectedClassId = this.classes[0].classId
    this.isFetching = false;
  }
})
