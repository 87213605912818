import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import {
  NurseryAttendanceResponse,
  SearchNurseryAttendanceResponse,
} from "chaild-api/lib";
import ApiAttendance, { SearchAttendanceRequest } from "@/api/ApiAttendance";
import dayjs from "dayjs";
import LocalDataService from "@/service/LocalDataService";

/* eslint-disable */

export interface AttendanceState {
  attendances: SearchNurseryAttendanceResponse | null;
  // for attendanceManagement
  todaysAttendances: NurseryAttendanceResponse[];
  date: string;
}

@Module({ dynamic: true, store, name: "attendance", namespaced: true })
class AttendanceModule extends VuexModule implements AttendanceState {
  public attendances: SearchNurseryAttendanceResponse | null = null;
  public todaysAttendances: NurseryAttendanceResponse[] = [];
  public date: string = dayjs().format("YYYY-MM-DD");

  @Action({ rawError: true })
  public async searchAttendance(input: SearchAttendanceRequest) {
    const response = await ApiAttendance.searchAttendance(input).catch();
    if (response) {
      this.setAttendances(response);
    }
    return response;
  }

  @Action({ rawError: true })
  public async listTodaysAttendances() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      let searchParams: {
        date: string;
        nurseryId: number;
        absence?: number;
        age?: number;
        classId?: number;
        firstName?: string;
        gender?: string;
        lastName?: string;
        limit?: number;
        skip?: number;
        status?: string;
      } = {
        nurseryId,
        date: this.date,
        limit: 1000,
        skip: 0,
      };

      const response = await ApiAttendance.searchAttendance(searchParams).catch(
        (e) => console.warn(e)
      );
      if (response) {
        this.setTodaysAttendances(response.attendances);
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async createAttendance(input: {
    childId: string;
    nurseryId: number;
    date?: string;
    attendedAt?: string;
    leftAt?: string;
  }) {
    const response = await ApiAttendance.createAttendance(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      return response;
    }
  }

  @Mutation
  public setAttendances(value: SearchNurseryAttendanceResponse) {
    this.attendances = value;
  }

  @Mutation
  public setTodaysAttendances(value: NurseryAttendanceResponse[]) {
    this.todaysAttendances = value;
  }

  @Mutation
  public setDate(date: string) {
    this.date = date;
  }

  @Mutation
  public async DownloadAttendance(input: {
    nurseryId: number;
    date: string;
    category: string;
  }) {
    const response = await ApiAttendance.DownloadAttendance(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      return response;
    }
  }
}

export const attendanceModule = getModule(AttendanceModule);
