
import dayjs from 'dayjs';
import Vue, { PropType } from 'vue';

interface DataType {
  headers: Record<string, string>;
}

type DateCellMeta = {
  label: string;
  year: number;
  month: number;
  date: number;
  day: number;
  dayString: string;
  shouldShow: boolean;
  isSelected: boolean;
}

export default Vue.extend({
  props: {
    yearMonth: {
      type: String
    },
    value: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },
  data: (): DataType => ({
    headers: {
      monday: '月',
      tuesday: '火',
      wednesday: '水',
      thursday: '木',
      friday: '金',
      saturday: '土',
      sunday: '日'
    }
  }),
  computed: {
    dateCells() {
      const month = dayjs(this.yearMonth).month()
      const endOfMonth = dayjs(this.yearMonth).endOf('month')
      const dateCells: DateCellMeta[][] = []
      let stdDay = dayjs(this.yearMonth).startOf('month').startOf('week').add(1, 'day')
      console.log('stdDay: ', stdDay.startOf('month').startOf('week').format('D'))

      // 前月の最終週から始める処理
      if (stdDay.startOf('month').startOf('week').format('D') === '1') {
        stdDay = stdDay.subtract(1, 'week');
      }

      while (true) {
        const line: DateCellMeta[] = []
        //for(let d = stdDay.day(); d < 7; d++) {
        for(let d = 0; d < 7; d++) {
          const date = stdDay.add(d, 'day')
          const isSelected = this.value.indexOf(date.date()) >= 0
          line.push({
            label: date.date() === 1 ? date.format('MMM D') : date.format('D'),
            year: date.year(),
            month: date.month(),
            date: date.date(),
            day: date.day(),
            dayString: '', // 'Sunday' or 'Monday' or ..
            shouldShow: month === date.month(),
            isSelected,
          })
        }
        dateCells.push(line)
        stdDay = stdDay.add(1, 'week').startOf('week').add(1, 'day')
        if (stdDay >= endOfMonth ) {
          break;
        }
      }
      
      return dateCells
    },
    yearMonthLabel() {
      return dayjs(this.yearMonth).format('YYYY年MM月')
    },
  },
  methods: {
    selectDate(date: number) {
      if (this.value.indexOf(date) >= 0) {
        const newValue = [...this.value].filter((v) => v !== date)
        this.$emit('input', newValue)
      } else {
        const newValue = [...this.value]
        newValue.push(date)
        this.$emit('input', newValue)
      }
    }
  }
})
