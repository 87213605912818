
import Vue from "vue";

import { schoolDairyModule } from "@/store/dataModules/schoolDiaryModule";
import dayjs from "dayjs";

interface DataType {
  isFetching: boolean;
  page: number;
}

export default Vue.extend({
  watch: {
    page: {
      handler(val) {
        schoolDairyModule.setPage(val);
        this.search();
      },
    },
  },

  computed: {
    diaries() {
      return [...schoolDairyModule.diaries].sort((d1, d2) => {
        const date1 = dayjs(d1.date);
        const date2 = dayjs(d2.date);
        return date2.diff(date1);
      });
    },
    totalItemsCount: function (): number {
      return schoolDairyModule.totalItemsCount;
    },
    from: {
      get() {
        return schoolDairyModule.from;
      },
      set(value: string) {
        schoolDairyModule.setFrom(value);
      },
    },
    to: {
      get() {
        return schoolDairyModule.to;
      },
      set(value: string) {
        schoolDairyModule.setTo(value);
      },
    },
    check: {
      get() {
        return schoolDairyModule.check;
      },
      set(value: boolean) {
        schoolDairyModule.setCheck(value);
      },
    },
    limit: function (): number {
      return schoolDairyModule.limit;
    },
  },

  data: (): DataType => {
    return {
      isFetching: false,
      page: 1,
    };
  },

  methods: {
    async search() {
      this.isFetching = true;
      if (
        schoolDairyModule.from != undefined && schoolDairyModule.to != undefined
      ) {
        if (schoolDairyModule.from != undefined && schoolDairyModule.to == "") {
          true
        } else if (schoolDairyModule.from > schoolDairyModule.to) {
          alert("開始日より終了日が過去の日付になっています")
          this.isFetching = false;
          return
        }
      }
      await schoolDairyModule.listSchoolDiaries();
      this.isFetching = false;
    },
  },

  mounted() {
    this.search();
  },
});
