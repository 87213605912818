import { NurseryAttendanceResponse } from "chaild-api/lib";
import ApiClient from "./ApiClient";

export type SearchAttendanceRequest = {
  date: string;
  nurseryId: number;
  absence?: number | string;
  age?: number | string;
  classId?: number | string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  limit?: number;
  skip?: number;
  status?: number | string;
};

export default class ApiAttendance {
  public static async searchAttendance(input: SearchAttendanceRequest) {
    const response = await ApiClient.get("/attendance/search", input);
    return response;
  }

  public static async getAttendance(input: {
    childId: number;
    nurseryId: number;
    date?: string;
  }) {
    const response = await ApiClient.get(
      `/children/${input.childId}/attendance`,
      {
        nurseryId: input.nurseryId,
        date: input.date,
      }
    );
    return response;
  }

  public static async createAttendance(input: {
    childId: number | string;
    nurseryId: number;
    date?: string;
    attendedAt?: string;
    leftAt?: string;
  }) {
    const response = await ApiClient.post(
      `/children/${input.childId}/attendance?nurseryId=${input.nurseryId}`,
      {
        date: input.date,
        attendedAt: input.attendedAt,
        leftAt: input.leftAt,
      }
    );
    if (response) {
      return response as NurseryAttendanceResponse;
    }
    return null;
  }

  public static async updateAttendance(input: {
    childId: number;
    nurseryId: number;
    attendanceId: number;
    attendedAt?: string;
    leftAt?: string;
  }) {
    const response = await ApiClient.put(
      `/children/${input.childId}/attendance/${input.attendanceId}?nurseryId=${input.nurseryId}`,
      {
        attendedAt: input.attendedAt,
        leftAt: input.leftAt,
      }
    );
    if (response) {
      return response as NurseryAttendanceResponse;
    }
    return null;
  }

  public static async absent(input: {
    childId: number;
    startedAt?: string;
    endedAt?: string;
    reason?: string;
    comment?: string;
  }) {
    const response = await ApiClient.post("/attendance", input);
    return response;
  }

  public static async deleteAttendance(input: {
    attendanceId: number;
    childId: number;
    nurseryId: number;
  }) {
    const response = await ApiClient.delete(
      `/children/${input.childId}/attendance/${input.attendanceId}`,
      input
    );
    return response;
  }

  public static async DownloadAttendance(input: {
    nurseryId: number;
    date: string;
    category: string;
    }) {
    const response = await ApiClient.post(`/attendance/${input.nurseryId}/${input.date}/export`, {
      category: input.category
    });
    return response;
  }
}
