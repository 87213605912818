
import Vue from 'vue';

import MonthPager from '@/components/atoms/MonthPager.vue';
import MonthDatePicker from '@/components/organisms/work-schedule/MonthDatePicker.vue'
import ScrollablePanels2 from '@/components/molecules/ScrollablePanels2.vue'
import HourMinuteInput from '@/components/molecules/HourMinuteInput.vue'
import dayjs from 'dayjs';
import { AttendanceScheduleMonthlySchema, AttendanceScheduleChildPartSchema, AttendanceScheduleDailyPartSchema, ChildDefaultSchedulePartSchema } from 'chaild-api/lib';
import { attendanceScheduleModule } from '@/store/viewModules/work-schedule/attendanceScheduleModule'

interface DataType {
  isFetching: boolean;
  isUpdatingSchedule: boolean;

  selectedClassId: number | string | null;

  addingUser: AttendanceScheduleChildPartSchema | null;
  addingDate: string | null;
  addingSchedule: UpdateChildAttendanceScheduleRequestParams | null;

  editingSchedule: AttendanceScheduleDailyPartSchema | null;
  editingUser: AttendanceScheduleChildPartSchema | null;

  bulkAddingUser: AttendanceScheduleChildPartSchema | null;
  bulkAddingSchedule: UpdateBulkAttendanceScheduleRequestParams | null;

  addingDefaultUser: AttendanceScheduleChildPartSchema | null;
  addChildDefaultSchedule: UpdateChildDefaultScheduleRequestParams | null;

  //園児基本スケジュール登録用
  header: Headers;
  defaultScheduleSelected: DefaultScheduleSelectItem;

  defaultScheduleItems: DefaultScheduleCheckdItem[];
  bulkDefaultMin: UpdateBulkDefaultRequestParams;
}

interface ScheduleTableRowItem {
  name: string;
  attendanceCount: number;
  offCount: number;
  schedules: AttendanceScheduleDailyPartSchema[];
  child: AttendanceScheduleChildPartSchema;
}

interface UpdateChildAttendanceScheduleRequestParams {
  date: string | number;
  childId: string | number;
  abbreviation?: string;
  color?: string;
  status?: 'attending' | 'isAbsent';
  statusReason?: string;
  statusComment?: string;
  startMin?: number;
  endMin?: number;
}

interface UpdateBulkAttendanceScheduleRequestParams {
  childId: string | number;
  abbreviation?: string;
  color?: string;
  status?: 'attending' | 'isAbsent';
  statusReason?: string;
  statusComment?: string;
  startMin?: number;
  endMin?: number;
  dates: number[];
}

//登園・降園のデフォルト設定ダイヤログのヘッダー
interface Headers { 
  names: string;
  dayOfWeek: {
  monday: string;
  tueday: string;
  wedday: string;
  thuday: string;
  friday: string;
  satday: string;
  };
}

//登園・降園のデフォルト設定ダイヤログのヘッダーチェックボックス
interface DefaultScheduleSelectItem {
  monday: boolean;
  tueday: boolean;
  wedday: boolean;
  thuday: boolean;
  friday: boolean;
  satday: boolean;
}

//登園・降園のデフォルト設定のチェックボックス用
type DefaultScheduleCheckdItem = {
  childId: number;
  days: {
    monday: boolean;
    tueday: boolean;
    wedday: boolean;
    thuday: boolean;
    friday: boolean;
    satday: boolean;
  };
  defaultMin: DefaultDayMin;
}

type DefaultDayMin = {
  monday: {
    startMin: number;
    endMin:   number;
  };
  tueday: {
    startMin: number;
    endMin:   number;
  };
  wedday: {
    startMin: number;
    endMin:   number;
  };
  thuday: {
    startMin: number;
    endMin:   number;
  };
  friday: {
    startMin: number;
    endMin:   number;
  };
  satday: {
    startMin: number;
    endMin:   number;
  };
}

//登園時間・降園時間
interface UpdateChildDefaultScheduleRequestParams {
  childId: number;
  Mins: ChildDefaultSchedulePartSchema;
}

interface UpdateBulkDefaultRequestParams {
  startMin: number;
  endMin: number;
}

const AbsenseReasons = [
  {
    text: '発熱',
    value: 'fever'
  },
  {
    text: '家庭の事情',
    value: 'personal'
  },
  {
    text: '溶連菌感染症',
    value: 'infStrep'
  },
  {
    text: 'マイコプラズマ肺炎',
    value: 'infMycoplasma'
  },
  {
    text: '手足口病',
    value: 'infHFMD'
  },
  {
    text: '伝染性紅斑（りんご病）',
    value: 'infSlappedCheek'
  },
  {
    text: 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）',
    value: 'infStomach'
  },
  {
    text: 'ヘルパンギーナ',
    value: 'infHerpangina'
  },
  {
    text: 'RSウィルス感染症',
    value: 'infRS'
  },
  {
    text: '帯状疱疹',
    value: 'infZoster'
  },
  {
    text: '突発性発しん',
    value: 'infRoseola'
  },
  {
    text: '伝染性膿痂疹（とびひ）',
    value: 'infImpetigo'
  },
  {
    text: 'その他',
    value: 'others'
  },
]

export default Vue.extend({
  components: {
    MonthPager,
    ScrollablePanels2,
    HourMinuteInput,
    MonthDatePicker,
},
  data: (): DataType => {
    return {
      isFetching: false,
      isUpdatingSchedule: false,

      selectedClassId: null,

      addingUser: null,
      addingDate: null,
      addingSchedule: null,

      editingSchedule: null,
      editingUser: null,

      bulkAddingUser: null,
      bulkAddingSchedule: null,

      addingDefaultUser: null,
      addChildDefaultSchedule: null,

      //登園・降園のデフォルト設定ダイヤログのヘッダー
      header: {
        names: '園児名',
        dayOfWeek: {
          monday: '月曜日',
          tueday: '火曜日',
          wedday: '水曜日',
          thuday: '木曜日',
          friday: '金曜日',
          satday: '土曜日'
        },
      },
      //登園・降園のデフォルト設定のチェックボックス用
      defaultScheduleSelected: {
        monday: false,
        tueday: false,
        wedday: false,
        thuday: false,
        friday: false,
        satday: false,
      },
      // 初期値として空の配列を設定
      defaultScheduleItems: [],
      bulkDefaultMin: {
        startMin: 0,
        endMin: 0
      }
    };
  },
  computed: {
    yearMonth: {
      get(): string {
        return attendanceScheduleModule.yearMonth
      },
      set(value: string) {
        this.onYearMonthChange(value)
      }
    },
    classes() {
      return attendanceScheduleModule.classes
    },
    attendanceSchedules() {
      return attendanceScheduleModule.attendanceSchedules
    },
    scheduleTableRows(): ScheduleTableRowItem[] {
      return this.attendanceSchedules.filter((schedule: AttendanceScheduleMonthlySchema) => {
        if (!this.selectedClassId) {
          return true
        }
        const findClass = schedule.child.nurseryClasses.find((nc) => nc.classId === this.selectedClassId)
        if (findClass) {
          return true
        }
        return false
      }).map((schedule: AttendanceScheduleMonthlySchema) => {
        let name = `${schedule.child.lastName} ${schedule.child.firstName}`
        if (name.length > 10) {
          name = name.slice(0, 10) + '...'
        }

        const formattedSchedules = schedule.dailySchedules.map((dailySchedule) => {
            if (dailySchedule.startMin == null) {
                // startMinがnullまたはundefinedの場合のデフォルト値を設定
                return {
                    ...dailySchedule,
                    startMinFormatted: '0:00',
                };
            }
            if (dailySchedule.endMin == null) {
                // startMinがnullまたはundefinedの場合のデフォルト値を設定
                return {
                    ...dailySchedule,
                    endMinFormatted: '0:00',
                };
            }
            const startHours = Math.floor(dailySchedule.startMin / 60); // 時
            const startMinutes = dailySchedule.startMin % 60; // 分
            const startFormattedTime = `${startHours}:${startMinutes.toString().padStart(2, '0')}`;
            const endHours = Math.floor(dailySchedule.endMin / 60); // 時
            const endMinutes = dailySchedule.endMin % 60; // 分
            const endFormattedTime = `${endHours}:${endMinutes.toString().padStart(2, '0')}`;


            return {
                ...dailySchedule,
                startMinFormatted: startFormattedTime,
                endMinFormatted: endFormattedTime,
                status: dailySchedule.status
            };
        });

        return {
          name,
          attendanceCount: 0,
          offCount: 0,
          schedules: formattedSchedules,
          child: schedule.child
        }
      })
    },
    numberOfDates(): number {
      const daysInMonth = dayjs(this.yearMonth).daysInMonth()
      return daysInMonth
    },
    tableDates(): Array<string> {
      const daysInMonth = dayjs(this.yearMonth).daysInMonth()
      const startOfMonth = dayjs(this.yearMonth).startOf('month')
      const dates = [...Array(daysInMonth)].map((emp, i) => {
        const date = dayjs(startOfMonth).add(i, 'day').locale('ja').format('M/D (ddd)')
        return date
      })

      return dates
    },
    isAddScheduleModalOpen: {
      get() {
        if (this.addingUser && this.addingDate) {
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.addingUser = null
          this.addingDate = null
          this.addingSchedule = null
        }
      }
    },
    //登園・降園のデフォルト設定ダイヤログ表示
    isAddChildDefaultScheduleModalOpen: {
      get() {
        if (this.addingDefaultUser) {
            // 各園児のチェックボックスを初期化して描画
            this.initializeChildCheckbox()
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.addingDefaultUser = null
        }
      }
    },
    isBulkAddScheduleModalOpen: {
      get() {
        if (this.bulkAddingUser) {
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.bulkAddingUser = null
          this.bulkAddingSchedule = null
        }
      }
    },
    isEditScheduleModalOpen: {
      get() {
        if (this.editingSchedule && this. editingUser) {
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.editingSchedule = null
          this.editingUser = null
        }
      }
    },
    absenseReasons() {
      return AbsenseReasons
    },
    absenseOptions() {
      return [
        {
          text: '出席',
          value: 'attending'
        },
        {
          text: '欠席',
          value: 'isAbsent'
        },
      ]
    },
  },
  methods: {
    async onYearMonthChange(value: string) {
      this.isFetching = true
      await attendanceScheduleModule.onYearMonthChange(value)
      this.isFetching = false
    },
    openAddScheduleModal(child: AttendanceScheduleChildPartSchema, date: string) {
      this.addingUser = child
      this.addingDate = date
      this.addingSchedule = {
        childId: child.childId,
        date: date,
      }

      if (child.defaultStartMin) {
        this.addingSchedule.startMin = child.defaultStartMin
      }

      if (child.defaultEndMin) {
        this.addingSchedule.endMin = child.defaultEndMin
      }
    },
    openEditScheduleModal(child: AttendanceScheduleChildPartSchema, schedule: AttendanceScheduleDailyPartSchema) {
      this.editingUser = child
      this.editingSchedule = schedule
    },
    openBulkAddScheduleModal(child: AttendanceScheduleChildPartSchema) {
      this.bulkAddingUser = child

      this.bulkAddingSchedule = {
        childId: child.childId,
        dates: [],
      }

      if (child.defaultStartMin) {
        this.bulkAddingSchedule.startMin = child.defaultStartMin
      }

      if (child.defaultEndMin) {
        this.bulkAddingSchedule.endMin = child.defaultEndMin
      }
    },
    // ダイアログが開かれたときに、defaultScheduleItems を初期化
    async initializeChildCheckbox() {
      this.isFetching = true
      this.defaultScheduleItems = await Promise.all(this.scheduleTableRows.map(async schedule => {
        const scheduleMins = await attendanceScheduleModule.getChildDefaultSchedule(schedule.child.childId);
         return {
          childId: schedule.child.childId,
          days: {
            monday: false,
            tueday: false,
            wedday: false,
            thuday: false,
            friday: false,
            satday: false
          },
          defaultMin: {
              monday: {
                startMin: scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.monStartMin : 0,
                endMin:   scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.monEndMin : 0
              },
              tueday: {
                startMin: scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.tueStartMin : 0,
                endMin:   scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.tueEndMin : 0
              },
              wedday: {
                startMin: scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.wedStartMin : 0,
                endMin:   scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.wedEndMin : 0
              },
              thuday: {
                startMin: scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.thuStartMin : 0,
                endMin:   scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.thuEndMin : 0
              },
              friday: {
                startMin: scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.friStartMin : 0,
                endMin:   scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.friEndMin : 0
              },
              satday: {
                startMin: scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.satStartMin : 0,
                endMin:   scheduleMins?.childId == schedule.child.childId ? scheduleMins?.Mins.satEndMin : 0
              },
            }
         };
      }));
      this.isFetching = false
    },
    // ダイアログが閉じられたときに呼ばれるメソッド
    isAddChildDefaultScheduleModalClose() {
      // ヘッダのチェックボックスを全てオフにする
      Object.keys(this.defaultScheduleSelected).forEach(day => {
        this.defaultScheduleSelected[day] = false;
      });

      // 各園児のチェックボックスを全てオフにする
      this.defaultScheduleItems.forEach(items => {
        Object.keys(items.days).forEach(day => {
          items.days[day] = false;
        });
      });
    },
    //登園・降園のデフォルト設定
    openAddChildDefaultScheduleModal(child: AttendanceScheduleChildPartSchema) {
      this.isUpdatingSchedule = true
      this.isFetching = true
      this.addingDefaultUser = child,
      this.bulkAddingSchedule = {
        childId: child.childId,
        dates: [],
      }
      if (child.defaultStartMin) {
        this.bulkAddingSchedule.startMin = child.defaultStartMin
      }
      if (child.defaultEndMin) {
        this.bulkAddingSchedule.endMin = child.defaultEndMin
      }
      this.isUpdatingSchedule = false
      this.isFetching = false
    },
    async addSchedule() {
      if (this.addingSchedule) {
        this.isUpdatingSchedule = true
        await attendanceScheduleModule.updateChildAttendanceSchedule(this.addingSchedule)
        this.isUpdatingSchedule = false
        this.isAddScheduleModalOpen = false
      }
    },
    async addDefaultSchedules() {
      this.isUpdatingSchedule = true
      this.isFetching = true
      await attendanceScheduleModule.addDefaultAttendanceSchedules();
      this.isUpdatingSchedule = false
      this.isFetching = false
    },
    async addBulkSchedule() {
      if (this.bulkAddingSchedule) {
        this.isUpdatingSchedule = true
        const schedule = { ...this.bulkAddingSchedule } as UpdateBulkAttendanceScheduleRequestParams

        await attendanceScheduleModule.updateChildAttendanceMonthlySchedule({
          childId: this.bulkAddingSchedule.childId,
          dailySchedules: schedule.dates.map((date) => {
            const d = dayjs(this.yearMonth).date(date).format('YYYY-MM-DD')
            
            return {
              date: d,
              abbreviation: null,
              color: null,
              status: schedule.status,
              statusReason: schedule.statusReason,
              statusComment: schedule.statusComment,
              startMin: schedule.startMin,
              endMin: schedule.endMin
            } as AttendanceScheduleDailyPartSchema
          })
        })
        this.isUpdatingSchedule = false
        this.isBulkAddScheduleModalOpen = false
      }
    },
    async saveEditingShcedule() {
      if (this.editingSchedule && this.editingUser) {
        this.isUpdatingSchedule = true
        await attendanceScheduleModule.updateChildAttendanceSchedule({
          date: this.editingSchedule.date,
          childId: this.editingUser.childId,
          abbreviation: this.editingSchedule.abbreviation || undefined,
          color: this.editingSchedule.color || undefined,
          status: this.editingSchedule.status || undefined,
          statusReason: this.editingSchedule.statusReason || undefined,
          startMin: this.editingSchedule.startMin || undefined,
          statusComment: this.editingSchedule.statusComment || undefined,
          endMin: this.editingSchedule.endMin || undefined
        })
        this.isUpdatingSchedule = false
        this.isEditScheduleModalOpen = false
      }
    },
    async deleteEditingShcedule() {
      if (this.editingSchedule && this.editingUser) {
        this.isUpdatingSchedule = true
        await attendanceScheduleModule.deleteChildAttendanceSchedule({
          date: this.editingSchedule.date,
          childId: this.editingUser.childId,
          abbreviation: this.editingSchedule.abbreviation || undefined,
          color: this.editingSchedule.color || undefined,
          status: this.editingSchedule.status || undefined,
          statusReason: this.editingSchedule.statusReason || undefined,
          startMin: this.editingSchedule.startMin || undefined,
          statusComment: this.editingSchedule.statusComment || undefined,
          endMin: this.editingSchedule.endMin || undefined
        })
        this.isUpdatingSchedule = false
        this.isEditScheduleModalOpen = false
      }
    },
    //登園・降園のデフォルト設定の登録
    async saveChildDefaultSchedule(){
      this.isUpdatingSchedule = true
      this.isFetching = true
      let checkCnt = 0

      if (
        this.bulkDefaultMin.startMin > this.bulkDefaultMin.endMin
        ||  this.bulkDefaultMin.startMin == this.bulkDefaultMin.endMin
      ) {
        this.isUpdatingSchedule = false
        this.isFetching = false
        return alert('登降園の時刻が前後または一致している場合は登録できません。');
      }
      // 各園児のデータを処理して新しい配列を作成する
      this.defaultScheduleItems.forEach((item) => {
        this.addChildDefaultSchedule = {
          childId: item.childId,
          Mins: {
            monStartMin: item.defaultMin.monday.startMin,
            monEndMin:   item.defaultMin.monday.endMin,
            tueStartMin: item.defaultMin.tueday.startMin,
            tueEndMin:   item.defaultMin.tueday.endMin,
            wedStartMin: item.defaultMin.wedday.startMin,
            wedEndMin:   item.defaultMin.wedday.endMin,
            thuStartMin: item.defaultMin.thuday.startMin,
            thuEndMin:   item.defaultMin.thuday.endMin,
            friStartMin: item.defaultMin.friday.startMin,
            friEndMin:   item.defaultMin.friday.endMin,
            satStartMin: item.defaultMin.satday.startMin,
            satEndMin:   item.defaultMin.satday.endMin,
          },
        };

        if (item.days.monday) {
              this.addChildDefaultSchedule.Mins.monStartMin = this.bulkDefaultMin.startMin;
              this.addChildDefaultSchedule.Mins.monEndMin   = this.bulkDefaultMin.endMin;
              item.defaultMin.monday.startMin = this.bulkDefaultMin.startMin;
              item.defaultMin.monday.endMin = this.bulkDefaultMin.endMin;
              checkCnt = 1;
          }
        if (item.days.tueday) {
            this.addChildDefaultSchedule.Mins.tueStartMin = this.bulkDefaultMin.startMin;
            this.addChildDefaultSchedule.Mins.tueEndMin   = this.bulkDefaultMin.endMin;
            item.defaultMin.tueday.startMin = this.bulkDefaultMin.startMin;
            item.defaultMin.tueday.endMin = this.bulkDefaultMin.endMin;
            checkCnt = 1;
        }
        if (item.days.wedday) {
            this.addChildDefaultSchedule.Mins.wedStartMin = this.bulkDefaultMin.startMin;
            this.addChildDefaultSchedule.Mins.wedEndMin   = this.bulkDefaultMin.endMin;
            item.defaultMin.wedday.startMin = this.bulkDefaultMin.startMin;
            item.defaultMin.wedday.endMin = this.bulkDefaultMin.endMin;
            checkCnt = 1;
        }
        if (item.days.thuday) {
            this.addChildDefaultSchedule.Mins.thuStartMin = this.bulkDefaultMin.startMin;
            this.addChildDefaultSchedule.Mins.thuEndMin   = this.bulkDefaultMin.endMin;
            item.defaultMin.thuday.startMin = this.bulkDefaultMin.startMin;
            item.defaultMin.thuday.endMin = this.bulkDefaultMin.endMin;
            checkCnt = 1;
        }
        if (item.days.friday) {
            this.addChildDefaultSchedule.Mins.friStartMin = this.bulkDefaultMin.startMin;
            this.addChildDefaultSchedule.Mins.friEndMin   = this.bulkDefaultMin.endMin;
            item.defaultMin.friday.startMin = this.bulkDefaultMin.startMin;
            item.defaultMin.friday.endMin = this.bulkDefaultMin.endMin;
            checkCnt = 1;
        }
        if (item.days.satday) {
            this.addChildDefaultSchedule.Mins.satStartMin = this.bulkDefaultMin.startMin;
            this.addChildDefaultSchedule.Mins.satEndMin   = this.bulkDefaultMin.endMin;
            item.defaultMin.satday.startMin = this.bulkDefaultMin.startMin;
            item.defaultMin.satday.endMin = this.bulkDefaultMin.endMin;
            checkCnt = 1;
        }

        attendanceScheduleModule.updateChildDefaultSchedule(this.addChildDefaultSchedule);
      });   

      if(checkCnt === 0){
        this.isUpdatingSchedule = false
        this.isFetching = false
        return alert('選択されていません');
      }

      Object.keys(this.defaultScheduleSelected).forEach(day => { 
        this.defaultScheduleSelected[day] = false;
      });

      // 各園児のチェックボックスを全てオフにする
      this.defaultScheduleItems.forEach(items => {
        Object.keys(items.days).forEach(day => {
          items.days[day] = false;
        });
      });
      
      this.isUpdatingSchedule = false
      this.isFetching = false
      return alert('保存しました');
    },
    //登園・降園のデフォルト設定の登録削除
    async deleteChildDefaultSchedule() {
      this.isUpdatingSchedule = true
      this.isFetching = true
      let checkCnt = 0
      // 各園児のデータを処理して新しい配列を作成する
      this.defaultScheduleItems.forEach((item) => {
        this.addChildDefaultSchedule = {
          childId: item.childId,
          Mins: {
            monStartMin: item.defaultMin.monday.startMin,
            monEndMin:   item.defaultMin.monday.endMin,
            tueStartMin: item.defaultMin.tueday.startMin,
            tueEndMin:   item.defaultMin.tueday.endMin,
            wedStartMin: item.defaultMin.wedday.startMin,
            wedEndMin:   item.defaultMin.wedday.endMin,
            thuStartMin: item.defaultMin.thuday.startMin,
            thuEndMin:   item.defaultMin.thuday.endMin,
            friStartMin: item.defaultMin.friday.startMin,
            friEndMin:   item.defaultMin.friday.endMin,
            satStartMin: item.defaultMin.satday.startMin,
            satEndMin:   item.defaultMin.satday.endMin,
          },
        };

        if (item.days.monday) {
            this.addChildDefaultSchedule.Mins.monStartMin = 0;
            this.addChildDefaultSchedule.Mins.monEndMin   = 0;
            item.defaultMin.monday.startMin = 0;
            item.defaultMin.monday.endMin = 0;
            checkCnt = 1;
          }
        if (item.days.tueday) {
            this.addChildDefaultSchedule.Mins.tueStartMin = 0;
            this.addChildDefaultSchedule.Mins.tueEndMin   = 0;
            item.defaultMin.tueday.startMin = 0;
            item.defaultMin.tueday.endMin = 0;
            checkCnt = 1;
        }
        if (item.days.wedday) {
            this.addChildDefaultSchedule.Mins.wedStartMin = 0;
            this.addChildDefaultSchedule.Mins.wedEndMin   = 0;
            item.defaultMin.wedday.startMin = 0;
            item.defaultMin.wedday.endMin = 0;
            checkCnt = 1;
        }
        if (item.days.thuday) {
            this.addChildDefaultSchedule.Mins.thuStartMin = 0;
            this.addChildDefaultSchedule.Mins.thuEndMin   = 0;
            item.defaultMin.thuday.startMin = 0;
            item.defaultMin.thuday.endMin = 0;
            checkCnt = 1;
        }
        if (item.days.friday) {
            this.addChildDefaultSchedule.Mins.friStartMin = 0;
            this.addChildDefaultSchedule.Mins.friEndMin   = 0;
            item.defaultMin.friday.startMin = 0;
            item.defaultMin.friday.endMin = 0;
            checkCnt = 1;
        }
        if (item.days.satday) {
            this.addChildDefaultSchedule.Mins.satStartMin = 0;
            this.addChildDefaultSchedule.Mins.satEndMin   = 0;
            item.defaultMin.satday.startMin = 0;
            item.defaultMin.satday.endMin = 0;
            checkCnt = 1;
        }

        attendanceScheduleModule.deleteChildDefaultSchedule(this.addChildDefaultSchedule);
      });

      if(checkCnt === 0){
        this.isUpdatingSchedule = false
        this.isFetching = false
        return alert('選択されていません');
      }

      Object.keys(this.defaultScheduleSelected).forEach(day => {
        this.defaultScheduleSelected[day] = false;
      });

      // 各園児のチェックボックスを全てオフにする
      this.defaultScheduleItems.forEach(items => {
        Object.keys(items.days).forEach(day => {
          items.days[day] = false;
        });
      });

      this.isUpdatingSchedule = false
      this.isFetching = false
      return alert('削除しました');
    },
    getRegisteredSchedule(date: number) {
      if (this.bulkAddingUser) {
        const findSchedules = this.attendanceSchedules.find((as) => as.child.childId === this.bulkAddingUser!.childId)
        if (findSchedules) {
          const targetDate = dayjs(`${this.yearMonth}-${date}`).format('YYYY-MM-DD')
          const dateSchedule = findSchedules.dailySchedules.find((ds) => ds.date === targetDate)
          console.log(`dateSchedule: ${JSON.stringify(dateSchedule)}`)
          if (dateSchedule) {
            return dateSchedule
          }
        }
      }
      return null
    },
    toggeleAll(DW: string) {
      // 全選択or全解除の判定
      const itemValue = this.defaultScheduleSelected[DW] ? true : false;

      // 各園児のチェックボックスに反映
      this.defaultScheduleItems.forEach((items) => {
        items.days[DW] = itemValue
      })
    }
  },
  async mounted() {
    this.isFetching = true
    await Promise.all([
      attendanceScheduleModule.listAttendanceSchedules(),
      attendanceScheduleModule.listClass(),
    ])
    this.isFetching = false
  }
})

