import ApiClient from "@/api/ApiClient";

export default class ApiNurseryReport {
  // 園の日誌
  public static async getNurseryReport(input: {
    nurseryId: number;
    date?: string;
  }) {
    try {
      const response = await ApiClient.get(
        `/nursery/${input.nurseryId}/diary`,
        {
          date: input.date,
        }
      );
      return response;
    } catch (e) {
      console.dir(e);
      return null;
    }
  }

  public static async listNurseryReports(input: {
    nurseryId: number;
    from?: string;
    to?: string;
    check: boolean;
    limit: number;
    skip: number;
  }) {
    const response = await ApiClient.get(`/nursery/diary/search`, input);
    if (response && response.diaries) {
      return response;
    }
    return null;
  }

  public static async createNurseryReport(input: {
    nurseryId: number;
    date?: string;
    aim?: string;
    activityAm?: string;
    activityPm?: string;
    comment?: string;
    event?: string;
    absentee?: string;
    status: "draft" | "applied";
  }) {
    const response = await ApiClient.post(`/nursery/${input.nurseryId}/diary`, {
      date: input.date,
      aim: input.aim,
      activityAm: input.activityAm,
      activityPm: input.activityPm,
      comment: input.comment,
      event: input.event,
      absentee: input.absentee,
      status: input.status,
    });
    return response;
  }

  public static async createSchoolDiary(input: {
    nurseryId: number;
    date?: string;
    comment?: string;
    event?: string;
    earlyLeaver?: string;
    appliedBy?: number;
    status: "draft" | "applied";
  }) {
    const response = await ApiClient.post(`/nursery/${input.nurseryId}/diary`, {
      date: input.date,
      comment: input.comment,
      event: input.event,
      earlyLeaver: input.earlyLeaver,
      appliedBy: input.appliedBy,
      status: input.status,
    });
    return response;
  }

  public static async updateSchoolDiary(input: {
    nurseryId: number;
    schoolDiaryId: number;
    comment?: string;
    event?: string;
    earlyLeaver?: string;
    appliedBy?: number;
    status: string;
  }) {
    const response = await ApiClient.put(
      `/nursery/${input.nurseryId}/diary/${input.schoolDiaryId}`,
      {
        comment: input.comment,
        event: input.event,
        earlyLeaver: input.earlyLeaver,
        appliedBy: input.appliedBy,
        status: input.status,
      }
    );
    return response;
  }

  public static async updateNurseryReport(input: {
    nurseryId: number;
    schoolDiaryId: number;
    date?: string;
    aim?: string;
    activityAm?: string;
    activityPm?: string;
    comment?: string;
    event?: string;
    absentee?: string;
    status: "draft" | "applied";
  }) {
    const response = await ApiClient.put(
      `/nursery/${input.nurseryId}/diary/${input.schoolDiaryId}`,
      {
        date: input.date,
        aim: input.aim,
        activityAm: input.activityAm,
        activityPm: input.activityPm,
        comment: input.comment,
        event: input.comment,
        absentee: input.absentee,
        status: input.status,
      }
    );
    return response;
  }

  public static async updateNurseryReportApproval(input: {
    nurseryId: number;
    schoolDiaryId: number;
    approvalStatus: "approved" | "rejected";
  }) {
    const response = await ApiClient.put(
      `/nursery/${input.nurseryId}/diary/${input.schoolDiaryId}/approval`,
      {
        approvalStatus: input.approvalStatus,
      }
    );
    return response;
  }
}
